import React from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import { PageType } from "../index"
import { Address, Phone } from "../components/Address";

const ImprintEn: PageType = ({ location }) => {
    return (
        <Layout location={location}>
            <Seo
                title="Legal Disclosure"
            />
            <h1 className="special-heading">Legal Disclosure</h1>
            <div className="textpage-content pb-32">
                <h2>Information according to § 5 TMG</h2>
                <p>
                    <Address />
                </p>

                <p>
                    UID-Nr.: DE352564738<br />
                    HRB-Nr.: 210 398<br />
                    Registergericht: Amtsgericht Lüneburg
                </p>

                <p>
                    <strong>Represented by:</strong><br />
                    Arne Schmidt
                </p>

                <h2>Contact</h2>
                <p>
                    Phone: <Phone /><br />
                    E-Mail: <a href="mailto:info@quadio.de">info@quadio.de</a>
                </p>

                <p>We are not willing or obliged to participate in dispute resolution proceedings before a consumer arbitration board.</p>

                <h3>Liability for contents</h3>
                <p>As a service provider, we are responsible for our own content on these pages according to § 7 para.1 TMG (German Telemedia Act) and general laws. According to §§ 8 to 10 TMG, we are not obliged to monitor transmitted or stored external information or to investigate circumstances that indicate illegal activity.</p>
                <p>Obligations to remove or block the use of information according to general laws remain unaffected. However, liability in this respect is only possible from the time of knowledge of a concrete infringement. If we become aware of any such infringements, we will remove these contents immediately.</p>

                <h3>Liability for links</h3>
                <p>Our offer contains links to external websites of third parties, on whose contents we have no influence. Therefore we cannot assume any liability for these external contents. The respective provider or operator of the sites is always responsible for the contents of the linked sites. The linked pages were checked for possible legal violations at the time of linking. Illegal contents were not recognizable at the time of linking. However, a permanent control of the contents of the linked pages is not reasonable without concrete evidence of a violation of the law. If we become aware of any infringements, we will remove such links immediately.</p>

                <h3>Copyright</h3>
                <p>The contents and works on these pages created by the site operators are subject to German copyright law. The reproduction, editing, distribution and any kind of use outside the limits of copyright law require the written consent of the respective author or creator. Downloads and copies of these pages are only permitted for private, non-commercial use.</p>
                <p>Insofar as the content on this site was not created by the operator, the copyrights of third parties are respected. In particular, third-party content is identified as such. Should you nevertheless become aware of a copyright infringement, please inform us accordingly. If we become aware of any infringements, we will remove such contents immediately.</p>

                <p>Source: <a href="https://www.e-recht24.de">https://www.e-recht24.de</a></p>
            </div>
        </Layout>
    )
}

export default ImprintEn
